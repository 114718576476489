//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, Ellipsis } from '@/components'
import { sysOnlineUserForceExist, sysOnlineUserList } from '@/api/modular/system/onlineUserManage'
export default {
  components: {
    STable,
    Ellipsis
  },
  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '賬號',
          dataIndex: 'account'
        },
        {
          title: '昵稱',
          dataIndex: 'nickName'
        },
        {
          title: '最後登錄IP',
          dataIndex: 'lastLoginIp'
        },
        {
          title: '最後登錄時間',
          dataIndex: 'lastLoginTime'
        },
        {
          title: '最後登錄地址',
          dataIndex: 'lastLoginAddress',
          scopedSlots: { customRender: 'lastLoginAddress' }
        },
        {
          title: '最後登錄瀏覽器',
          dataIndex: 'lastLoginBrowser',
          scopedSlots: { customRender: 'lastLoginBrowser' }
        },
        {
          title: '最後登錄所用系統',
          dataIndex: 'lastLoginOs'
        }
      ],
      loading: true,
      loadData: parameter => {
        return sysOnlineUserList(Object.assign(parameter, this.queryParam)).then((res) => {
          if (this.hasPerm('sysOnlineUser:list')) {
            return res.data
          } else {
            return new Promise((resolve, reject) => {
              return resolve()
            })
          }
        })
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  // 進頁面加載
  created () {
    if (this.hasPerm('sysOnlineUser:forceExist')) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    forceExist (record) {
      sysOnlineUserForceExist(record).then((res) => {
        if (res.success) {
          this.$message.success('強制下線成功')
          // 重新加載表格
          this.$refs.table.refresh()
        } else {
          this.$message.error('強制下線失敗：' + res.message)
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
